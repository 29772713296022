export function getCurrentDateAndTimeinAuFormat() {
  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-AU");
  return formattedDate;
}

export function convertAuDateIntoApiDate(auDate) {
  if (auDate) {
    const parts = auDate.split("/");
    const outputDate = `${parts[2]}-${parts[1].padStart(
      2,
      "0"
    )}-${parts[0].padStart(2, "0")}`;
    return outputDate;
  }
}

export function convertDateAndTimeinAuFormat(dateString) {
  // const date = new Date('2023-04-05T13:32:56');
  if (dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-AU");

    const timeOptions = { hour: "2-digit", minute: "2-digit" };

    const formattedTime = date.toLocaleTimeString("en-AU", timeOptions);

    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  } else {
    return dateString;
  }
}

export function convertDateinAuFormat(dateString) {
  // const date = new Date('2023-04-05T13:32:56');
  if (dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-AU");
    return formattedDate;
  } else {
    return dateString;
  }
}

export function getAccessTokenExpiryDateTime(accessTokenExpiryInMS) {
  const currentDate = new Date();
  const newDate = new Date(currentDate.getTime() + accessTokenExpiryInMS);

  const formattedDate = newDate.toLocaleDateString("en-AU");

  // const timeOptions = { hour: "2-diˇgit", minute: "2-digit" };

  const formattedTime = newDate.toLocaleTimeString("en-AU");

  const formattedDateTime = `${formattedDate} ${formattedTime}`;
  
  return formattedDateTime
}
