// src/domain/models/Competitor.js

class Competitor {


  
  constructor(competitorId = null,competitorName=null, competitorEmail = null, competitorDob = null, status = null, dateCreated = null, dateUpdated = null) {
    
    this.competitorId = competitorId;
    this.competitorName=competitorName
    this.competitorEmail = competitorEmail;
    this.competitorDob = competitorDob
    this.status = status
    this.dateCreated = dateCreated
    this.dateUpdated = dateUpdated
  }

  // getCompetitorId() {
  //   return this.competitorId;
  // }

  // getCompetitorFirstName() {
  //   return this.competitorFirstName;
  // }

  // getCompetitorLastName() {
  //   return this.competitorLastName;
  // }

  // getCompetitorEmail() {
  //   return this.competitorEmail;
  // }


  // setCompetitorFirstName(competitorFirstName) {
  //   this.competitorFirstName = competitorFirstName;
  // }


}

export default Competitor;
