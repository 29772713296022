
class Competition {
    constructor(competitionId=null, competitionStatus=null, competitionDesc=null, competitionDate=null,section=null,status=null,dateCreated=null,dateUpdated=null) {
      this.competitionId = competitionId;
      this.competitionStatus = competitionStatus;
      this.competitionDesc = competitionDesc;
      this.competitionDate = competitionDate;
      this.section=section
      this.status=status
      this.dateCreated=dateCreated
      this.dateUpdated=dateUpdated
    }
  
    getCompetitionId() {
      return this.competitionId;
    }

    getSection() {
      return this.section;
    }
  
    setSection(section) {
      this.section = section;
    }
  

  }
  
  export default Competition;
  