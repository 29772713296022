
import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, PAGE_LIMIT, API_FIELDS_PARTICIPATES_IN, API_FIELDS_STATUS, API_ENDPOINTS_OBJECT } from '../../utils/constants/api_constants';
import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import FieldParams from '../models/params/FieldParams';
import { convertAuDateIntoApiDate } from '../../utils/dateUtils';



class ParticipatesInRepository {

  async getParticipatesInById(participatesInId) {
    const fields = FieldParams.fetchFieldsForParticipatesIn()
    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}/${participatesInId}?fields=${fields}`);
    const participatesInData = response.data;
    const participatesInById = Mapper.mapToParticipatesInModel(participatesInData);
    return participatesInById
  }

  async getAllParticipatesIn() {
    const fields = FieldParams.fetchFieldsForParticipatesIn()
    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?fields=${fields}`);
    const participatesInData = response.data;

    const participatesInDataModel = []

    for (let i = 0; i < participatesInData.length; i++) {
      participatesInDataModel.push(Mapper.mapToParticipatesInModel(participatesInData[i]));
    }

    return participatesInDataModel;
  }


  async getAllParticipatesInWithFilter(startDate, endDate, sectionId, competitorId, page, limit) {
    const startDateToSend = convertAuDateIntoApiDate(startDate)
    const endDateToSend = convertAuDateIntoApiDate(endDate)

    const filterParamsforParticipates = JSON.stringify(Params.fetchParticipatesInAllParams(startDateToSend, endDateToSend, sectionId, competitorId))

    const fields = FieldParams.fetchFieldsForParticipatesIn()
    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?limit=${limit}&meta=filter_count&page=${page}&fields=${fields}&filter=${filterParamsforParticipates}&sort=order_no`);

    // const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?limit=${PAGE_LIMIT}&fields=${fields}&filter=${filterParamsforParticipates}`);
    const participatesInData = response.data;

    const participatesInDataModel = []

    for (let i = 0; i < participatesInData.length; i++) {
      participatesInDataModel.push(Mapper.mapToParticipatesInModel(participatesInData[i]));
    }

    const meta = response.meta;
    return {
      "filterCount": meta.filter_count,
      "participatesInDataModel": participatesInDataModel
    }
  }

  async updateParticipatesIn(participatesInModel) {

    const competitorJson = JSON.stringify(Mapper.mapCompetitorToJson(participatesInModel));
    const response = await apiClient.post(`${API_ENDPOINTS_ITEMS.COMPETITOR}}`, competitorJson);
    const competitorData = response.data;
    const competitor = Mapper.mapToCompetitorModel(competitorData);
    return competitor
  }

  async updateParticipatesInRankWithCheck(participatesIn, rank) {
    if (rank == '1' || rank == '2' || rank == '3') {
      const rankExists = await this.checkIfRankExistsforGivenCompetition(participatesIn.competition.competitionId, rank)
      if (rankExists) {
        throw new Error(`A competitor with placing ${rank} already exists.`);
      }
      else {
        return this.updateParticipatesInRank(participatesIn, rank)
      }
    }

    else {
      return this.updateParticipatesInRank(participatesIn, rank)
    }

  }


  async updateParticipatesInRank(participatesIn, rank) {

    const participatesInId = participatesIn.participatesInId
    const updateParticipatesRankModel = { [API_FIELDS_PARTICIPATES_IN.RANK]: rank }

    const participatesRankModel = JSON.stringify(updateParticipatesRankModel);
    const response = await apiClient.patch(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}/${participatesInId}`, updateParticipatesRankModel);
    const participateInData = response.data;

    const participateInDataModel = Mapper.mapToParticipatesWithoutField(participateInData);

    return participateInDataModel

  }

  async checkIfRankExistsforGivenCompetition(competitionId, rank) {

    const filterParamsforParticipates = JSON.stringify(Params.fetchParticipatesInByCompetitionAndRank(competitionId, rank))

    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?filter=${filterParamsforParticipates}`);
    const participatesInData = response.data;

    if (participatesInData.length > 0) {
      return true
    }
    return false;
  }


  async getParticipatesInByCompetitorAndCompetition(competitionId, competitorId) {

    const filterParamsforParticipatesIn = JSON.stringify(Params.fetchParticipatesInByCompetitionAndCompetitor(competitionId, competitorId))

    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?filter=${filterParamsforParticipatesIn}`);
    const participateInData = response.data;
    const participateInDataModel = []
    for (let i = 0; i < participateInData.length; i++) {
      participateInDataModel.push(Mapper.mapToParticipatesWithoutField(participateInData[i]));
    }
    return participateInDataModel
  }

  async createParticipatesIn(participatesInModel) {

    const participatesInModelJsonObject = {
      [API_FIELDS_STATUS.STATUS]: API_FIELDS_STATUS.PUBLISHED,
      [API_ENDPOINTS_OBJECT.COMPETITION]: participatesInModel.competition,
      [API_ENDPOINTS_OBJECT.COMPETITOR]: participatesInModel.competitor,
      [API_FIELDS_PARTICIPATES_IN.ORDER_NO]: participatesInModel.orderNo,

    };
    console.log('participatesInModelJsonObject', participatesInModelJsonObject)


    const response = await apiClient.post(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}`, participatesInModelJsonObject);
    const participatesInData = response.data;
    const participatesInModelCreate = Mapper.mapToParticipatesWithoutField(participatesInData);
    return participatesInModelCreate
  }

  async createParticipatesWithCompetitionAndCompetitorExistsCheck(participatesInModel) {
    let participatesInModelResponse = participatesInModel


    try {
      const participatesInData = await this.getParticipatesInByCompetitorAndCompetition(participatesInModel.competition, participatesInModel.competitor);
      console.log('particiaptesInData', participatesInData)

      if (participatesInData.length === 0) {
        participatesInModelResponse = await this.createParticipatesIn(participatesInModel);
        console.log('ParticipatesIn does not exist in database. Created ParticipatesIn now and got this response:', participatesInModelResponse);
      }
      else{
        participatesInModelResponse = participatesInData[0]
        console.log(`Participation Exists Database Order number: ${participatesInModelResponse.orderNo} exist in database. Competition Id is ${participatesInModelResponse.participatesInId}`);
      }

   
    }

    catch (error) {
      throw new Error(`Error while creating Participation: ${error}`);

    }

    return participatesInModelResponse
  }


}

export default ParticipatesInRepository;
