import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, PAGE_LIMIT, API_FIELDS_PARTICIPATES_IN, RANK } from '../../utils/constants/api_constants';
import { generateCertificate } from '../../utils/constants/certificate-v1';
import { getCerticateHTML } from '../../utils/certificate';


import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import FieldParams from '../models/params/FieldParams';
import Certificate from '../models/CertificateModel';


import html2pdf from 'html2pdf.js';


class CertificateRepository {

    async createCertificate(certificateData) {

        const options = {
            margin: 0.5,
            filename: 'certificate.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
        };

        let pdfUrl = ''

        // const htmlContent = "<h1>Hello, World!</h1>";

        const htmlContent = getCerticateHTML(certificateData)

        const htmlElement = document.createElement("div");
        htmlElement.innerHTML = htmlContent;


        // html2pdf().from(htmlContent).set(options).save();





        html2pdf().set(options).from(htmlContent).toPdf().get('pdf').then(function (pdf) {
            // set the PDF URL to the state variable
            pdfUrl = URL.createObjectURL(pdf.output('blob'));
            window.open(pdfUrl, "_blank");

        });
        // return pdfUrl
    }


    async generateCertificateFromAWSLambda(certificateData) {

        // const certificateData=certificate
        this.getCertificateLayout(certificateData)
        certificateData.rank = Mapper.mapRank(certificateData.rank)

        const htmlString = getCerticateHTML(certificateData)
        const request_body =
        {
            "bucket": "dubbo-eisteddfod",
            "wkhtmltopdf_options": {
                "orientation": "landscape",
                "title": certificateData.awardedTo,
                "margin": "10mm 10mm 10mm 10mm"
            },
            "html_string": htmlString
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(request_body),
        };


        const response = await fetch(`https://jscian5g4m.execute-api.ap-southeast-2.amazonaws.com/dev/convert-html-to-pdf`, options);
        if (!response.ok) {
            console.error("Error while sending document to AWS Lambda:", response.json)
            throw new Error(`Request failed: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }

    getCertificateLayout(certificate) {
        let awardPrizeLeft = '26%';
        let awardNameLeft = '32%';
        let awardSectionLeft = '26%';
        let awardNameFontSize = '90px';

        //Rank check
        if (certificate.rank == 0) {
            awardPrizeLeft = '45%';
        }
        else if (certificate.rank == 5) {
            awardPrizeLeft = '22%';
        }
        else if (certificate.rank == 4) {
            awardPrizeLeft = '26%';
        }
        else if (certificate.rank == 3) {
            awardPrizeLeft = '36%';
        }
        else if (certificate.rank == 2) {
            awardPrizeLeft = '34%';
        }
        else if (certificate.rank == 1) {
            awardPrizeLeft = '36%';
        }

        //Certificate Name Check
        if (certificate.awardedTo.length < 5) {
            awardNameLeft = '43%'
        }

        else if (certificate.awardedTo.length >= 5 && certificate.awardedTo.length < 10) {
            awardNameLeft = '36%'
        }
        else if (certificate.awardedTo.length >= 10 && certificate.awardedTo.length < 15) {
            awardNameLeft = '32%'
        }
        else if (certificate.awardedTo.length >= 15 && certificate.awardedTo.length < 20) {
            awardNameLeft = '28%'
        }
        else if (certificate.awardedTo.length >= 20 && certificate.awardedTo.length < 25) {
            awardNameLeft = '22%'
        }
        else if (certificate.awardedTo.length >= 25 && certificate.awardedTo.length < 30) {
            awardNameLeft = '20%'
            awardNameFontSize = '85px'
        }
        else if (certificate.awardedTo.length >= 30 && certificate.awardedTo.length < 35) {
            awardNameLeft = '14%'
            awardNameFontSize = '80px'
        }
        else if (certificate.awardedTo.length >= 35 && certificate.awardedTo.length < 40) {
            awardNameLeft = '12%'
            awardNameFontSize = '70px'
        }
        else if (certificate.awardedTo.length >= 40 && certificate.awardedTo.length < 45) {
            awardNameLeft = '10%'
            awardNameFontSize = '62px'
        }
        else if (certificate.awardedTo.length >= 45 && certificate.awardedTo.length < 50) {
            awardNameLeft = '6%'
            awardNameFontSize = '58px'
        }
        else if (certificate.awardedTo.length >= 50 && certificate.awardedTo.length < 60) {
            awardNameLeft = '5%'
            awardNameFontSize = '53px'
        }
        else if (certificate.awardedTo.length >= 60) {
            awardNameLeft = '5%'
            awardNameFontSize = '48px'
            // certificate.awardedTo = certificate.awardedTo.substring(0, 25);
        }


        //Award Section Check
        if (certificate.awardedfor.length < 5) {
            awardSectionLeft = '36%'
        }

        else if (certificate.awardedfor.length >= 5 && certificate.awardedfor.length < 10) {
            awardSectionLeft = '48%'
        }
        else if (certificate.awardedfor.length >= 10 && certificate.awardedfor.length < 15) {
            awardSectionLeft = '45%'
        }
        else if (certificate.awardedfor.length >= 15 && certificate.awardedfor.length < 20) {
            awardSectionLeft = '43%'
        }
        else if (certificate.awardedfor.length >= 20 && certificate.awardedfor.length < 25) {
            awardSectionLeft = '40%'
        }
        else if (certificate.awardedfor.length >= 25 && certificate.awardedfor.length < 30) {
            awardSectionLeft = '40%'
        }
        else if (certificate.awardedfor.length >= 30 && certificate.awardedfor.length < 35) {
            awardSectionLeft = '38%'
        }
        else if (certificate.awardedfor.length >= 35 && certificate.awardedfor.length < 40) {
            awardSectionLeft = '37%'
        }
        else if (certificate.awardedfor.length >= 40 && certificate.awardedfor.length < 45) {
            awardSectionLeft = '36%'
        }
        else if (certificate.awardedfor.length >= 45 && certificate.awardedfor.length < 50) {
            awardSectionLeft = '34%'
        }
        else if (certificate.awardedfor.length >= 50 && certificate.awardedfor.length < 55) {
            awardSectionLeft = '32%'
        }
        else if (certificate.awardedfor.length >= 55 && certificate.awardedfor.length < 60) {
            awardSectionLeft = '30%'
        }
        else if (certificate.awardedfor.length >= 60 && certificate.awardedfor.length < 70) {
            awardSectionLeft = '28%'
        }
        else if (certificate.awardedfor.length >= 70 && certificate.awardedfor.length < 80) {
            awardSectionLeft = '22%'
        }

        else if (certificate.awardedfor.length >= 80 && certificate.awardedfor.length < 90) {
            awardSectionLeft = '18%'
        }
        else if (certificate.awardedfor.length >= 90 && certificate.awardedfor.length < 100) {
            awardSectionLeft = '14%'
        }
        else if (certificate.awardedfor.length >= 100 && certificate.awardedfor.length < 110) {
            awardSectionLeft = '10%'
        }
        else if (certificate.awardedfor.length >= 110) {
            awardSectionLeft = '4%'
            // certificate.awardedfor = certificate.awardedfor.substring(0, 120);
        }


        let certificateLayout = {
            'awardPrizeLeft': awardPrizeLeft,
            'awardNameLeft': awardNameLeft,
            'awardSectionLeft': awardSectionLeft,
            'awardNameFontSize': awardNameFontSize
        }

        certificate.certificateLayout = certificateLayout

        return certificate
    }

}

export default CertificateRepository;