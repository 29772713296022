class ParticipatesIn {
    constructor(participatesInId = null, rank = null, orderNo = null, competitor = null, competition = null, status = null, dateCreated = null, dateUpdated = null) {
        this.participatesInId = participatesInId;
        this.rank = rank;
        this.orderNo = orderNo;
        this.competitor = competitor;
        this.competition = competition
        this.status = status
        this.dateCreated = dateCreated
        this.dateUpdated = dateUpdated
    }

    getParticipatesInId() {
        return this.participatesInId;
    }
    getParticipatesInCompetitor() {
        return this.competitor;
    }
    getParticipatesInCompetition() {
        return this.competition;
    }

    setParticipatesInCompetitor(competitor) {
        this.competitor = competitor;
    }
    setParticipatesInCompetition(competition) {
        this.competition = competition;
    }


}

export default ParticipatesIn;