import Competitor from '../CompetitorModel';
import Competition from '../CompetitionModel';
import ParticipatesIn from '../ParticipatesInModel';
import CsvUpload from '../CsvUploadModel';
import Section from '../SectionModel';
import { convertDateAndTimeinAuFormat, convertDateinAuFormat } from '../../../utils/dateUtils';


import { API_ENDPOINTS_ITEMS, API_FIELDS_STATUS, API_FIELDS_COMPETITOR, CSV_COLUMNS, API_FIELDS_SECTION, RANK,RANK_TO_DISPLAY } from '../../../utils/constants/api_constants';



function mapToCompetitorModel(competitor) {
  const { competitor_id, competitor_name, competitor_email, competitor_dob, status, date_created, date_updated } = competitor;
  return new Competitor(competitor_id, competitor_name, competitor_email, competitor_dob, status, date_created, date_updated);
}

// function mapCompetitorToJson(competitorModel) {

//   const competitorModelJsonObject = {};
//   competitorModelJsonObject[API_FIELDS_STATUS.STATUS] = competitorModel.status


//   if (competitorModel.competitorId) {
//     competitorModelJsonObject[API_FIELDS_COMPETITOR.COMPETITOR_ID] = competitorModel.competitorId
//   }
//   if (competitorModel.competitorName) {
//     competitorModelJsonObject[API_FIELDS_COMPETITOR.COMPETITOR_NAME] = competitorModel.competitorName
//   }

//   if (competitorModel.competitorEmail) {
//     competitorModelJsonObject[API_FIELDS_COMPETITOR.COMPETITOR_EMAIL] = competitorModel.competitorEmail
//   }
//   if (competitorModel.competitorDob) {
//     competitorModelJsonObject[API_FIELDS_COMPETITOR.COMPETITOR_DOB] = competitorModel.competitorDob
//   }

//   return competitorModelJsonObject

// }



function mapPapaParseCsvToCsvModel(item) {
  return new CsvUpload(item[CSV_COLUMNS.COMPETITORS_NAME], item[CSV_COLUMNS.SECTION_CODE], item[CSV_COLUMNS.SECTION_DESC], item[CSV_COLUMNS.COMPETITOR_ORDER_NO], item[CSV_COLUMNS.COMPETITION_DATE], item[CSV_COLUMNS.COMPETITOR_EMAIL_ADDRESS])
}


function mapToSectionModel(section) {
  const { section_id, section_code, section_desc, status, date_created, date_updated } = section;

  return new Section(section_id, section_code, section_desc, status, date_created, date_updated);
}

function mapToCompetitionModel(competition) {
  const { competition_id, competition_status, competition_desc, competition_date, section, status, date_created, date_updated } = competition;

  const sectionModel = mapToSectionModel(section)
  const competitionDate = convertDateinAuFormat(competition_date)

  return new Competition(competition_id, competition_status, competition_desc, competitionDate, sectionModel, status, date_created, date_updated);
}

function mapToParticipatesInModel(participatesIn) {

  const { participates_in_id, rank, order_no, competitor, competition, status, dateCreated, dateUpdated } = participatesIn;
  const competitorModel = mapToCompetitorModel(competitor)
  const competititionModel = mapToCompetitionModel(competition)
  const userRank=mapRankUserDisplay(rank)
  return new ParticipatesIn(participates_in_id, userRank, order_no, competitorModel, competititionModel, status, dateCreated, dateUpdated);
}

function mapToParticipatesWithoutField(participatesIn) {
  const { participates_in_id, rank, order_no, competitor, competition, status, dateCreated, dateUpdated } = participatesIn;
  const userRank=mapRankUserDisplay(rank)
  return new ParticipatesIn(participates_in_id, userRank, order_no, competitor, competition, status, dateCreated, dateUpdated);
}

function mapRank(rank) {
  if (rank == 1) {
    return RANK.FIRST
  }
  else if (rank == 2) {
    return RANK.SECOND
  }
  else if (rank == 3) {
    return RANK.THIRD
  }
  else if (rank == 4) {
    return RANK.HIGHLY_COMMENDED
  }
  else if (rank == 5) {
    return RANK.ENCOURAGEMENT_AWARD
  }
  else if (rank == 0) {
    return RANK.N_A
  }
  else return RANK.N_A
}

function mapRankUserDisplay(rank) {
  if (rank == 1) {
    return RANK_TO_DISPLAY.FIRST
  }
  else if (rank == 2) {
    return RANK_TO_DISPLAY.SECOND
  }
  else if (rank == 3) {
    return RANK_TO_DISPLAY.THIRD
  }
  else if (rank == 4) {
    return RANK_TO_DISPLAY.HIGHLY_COMMENDED
  }
  else if (rank == 5) {
    return RANK_TO_DISPLAY.ENCOURAGEMENT_AWARD
  }
  else if (rank == 0) {
    return RANK_TO_DISPLAY.N_A
  }
  else return RANK_TO_DISPLAY.N_A
}

function mapUserDisplayRankToDb(rank) {
  if (rank == RANK_TO_DISPLAY.FIRST) {
    return 1
  }
  else if (rank == RANK_TO_DISPLAY.SECOND) {
    return 2
  }
  else if (rank == RANK_TO_DISPLAY.THIRD) {
    return 3
  }
  else if (rank == RANK_TO_DISPLAY.HIGHLY_COMMENDED) {
    return 4
  }
  else if (rank == RANK_TO_DISPLAY.ENCOURAGEMENT_AWARD) {
    return 5
  }
  else if (rank == RANK_TO_DISPLAY.N_A) {
    return 0
  }
  else return 0
}

export default {
  mapToCompetitorModel,
  mapToParticipatesInModel,
  mapToSectionModel,
  mapToParticipatesWithoutField,
  mapPapaParseCsvToCsvModel,
  mapToCompetitionModel,
  mapRank,
  mapUserDisplayRankToDb,
};