import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
} from "react-router-dom";

import Table from "./presentation/components/Table";
import Navbar from "./presentation/components/Navbar";
import Home from "./presentation/components/Home";
import LoginForm from "./presentation/components/LoginForm";
import OneOffCertificate from "./presentation/components/OneOffCertificate";

import AddCompetitor from "./presentation/components/AddCompetitor";
import AddSection from "./presentation/components/AddSection";
import Footer from "./presentation/components/common/Footer";
import Competition from "./presentation/components/Competition";
import CompetitorDetails from "./presentation/components/CompetitorDetails";
import UpdateCompetitor from "./presentation/components/UpdateCompetitor";
import CsvUploader from "./presentation/components/CsvUploader";
import CompetitionPage from "./presentation/components/CompetitionsPage";

import ErrorBoundary from "./presentation/components/common/ErrorBoundary";
import AuthRepository from "./domain/repositories/AuthRepository";

// import PrivateRoute from "./components/PrivateRoute";

import React, { useState, useEffect, Component } from "react";
import CompetitionsPage from "./presentation/components/CompetitionsPage";

const PrivateRoute = ({ element: Element, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    element={isAuthenticated ? <Element /> : <Navigate to="/login" />}
  />
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authRepository = new AuthRepository();

  const checkIsAuthenticated = async () => {
    const checkAuthenticated = await authRepository.isAuthenticated();
    setIsAuthenticated(checkAuthenticated);
  };
  checkIsAuthenticated();

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    await authRepository.logout();
    setIsAuthenticated(false);
  };

  return (
    <div className="App">
      <Router>
        {/* <Route exact path="/competitor/:id" element={<CompetitorDetails/>}/> */}

        <div>
          <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} />

          <Routes>
            <Route
              exact
              path="/"
              element={isAuthenticated ? <Home onLogout={handleLogout}/> : <Navigate to="/login" />}
            />

          <Route exact path="/oneoff" element={isAuthenticated ? <OneOffCertificate /> : <Navigate to="/login" />}/>

            <Route
              exact
              path="/upload/csv"
              element={
                isAuthenticated ? <CsvUploader /> : <Navigate to="/login" />
              }
            />

            {/* <Route exact path="/login" element={<LoginForm/>}/> */}

            <Route
              exact
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/" />
                ) : (
                  <LoginForm onLogin={handleLogin} />
                )
              }
            />


            {/* 
          <Route exact path="/" element={<Home/>}/> 

          <Route exact path="/competition" element={<CompetitionsPage/>}/>

          <Route exact path="/login" element={<LoginForm/>}/>
          <Route exact path="/add/competitor" element={<AddCompetitor/>}/>
          <Route exact path="/add/section" element={<AddSection/>}/>

          <Route exact path="/competitor/:id" element={<CompetitorDetails/>}/>
          <Route exact path="/update/competitor/:competitorId" element={<UpdateCompetitor/>}/> */}
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
