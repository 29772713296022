
//ERROR MESSAGES
export const SOMETHING_WENT_WRONG ='Something went wrong. Please try again later.';
export const FIRST_NAME_ERROR ='First name is required.';
export const LAST_NAME_ERROR ='Last name is required.';
export const VALID_EMAIL_ERROR ='Please enter valid email address';
export const VALID_SECTION_DESCRIPTION_ERROR ='Please enter valid section description';
export const VALID_SECTION_CODE_ERROR ='Please enter valid section code';

//

//SUCCESS MESSAGES
export const SUCCESSFULLY_ADDED ='Successfully added';
export const SUCCESSFULLY_UPDATED ='Successfully updated';

// LOGIN SPECIFIC
export const LOGIN_MESSAGE = {
    INVALID_USERNAME_PASSWORD: 'Invalid Username or Password',
    USERNAME_VALID: 'Please enter a valid username',
    PASSWORD_VALID: 'Password complexity has not met',
  }

// ERROR MESSAGES
export const ERROR_MESSAGE = {
    SOMETHING_WENT_WRONG :'Something went wrong. Please try again later.',
  }