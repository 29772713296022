export function validateEmail(email) {
  if (email.length == '') {
    return true
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function validatePassword(password) {
  return password.length >= 8;
}

export function validateName(name) {
  return name.length != '';
}

export function validateEmptyString(stringToCheck) {
  return stringToCheck.length != '';
}

export function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = today.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${year}-${month}-${day}`;
};