import Certificate from '../domain/models/CertificateModel';


export function getCerticateHTML(certificate) {

    const certificateAsString = certifcateHTMLString(certificate)

    return certificateAsString

}

function fetchHtmlfromTxtfile(certifcateHTMLStringWithData) {
    let textContent = '';
    fetch('certificate.txt')
        .then(response => response.text())
        .then(data => {
            textContent = data;
            console.log('Certificate Content Here: '); // The contents of the text file as a string
            console.log(textContent); // The contents of the text file as a string
        })
        .catch(error => console.error(error));
    return textContent
}


function certifcateHTMLStringWithData(certificate) {

    const html = `<!DOCTYPE html><html><head><style>.outer-border{width:930px;height:590px;padding:{20px};text-align:center;border:10px solid #2c624a;margin-right:21%;}.inner-dotted-border{width:880px;height:540px;padding:20px;text-align:center;border:5px solid #2c624a;border-style:dotted;}.certification{font-size:50px;font-weight:bold;color:#2c624a;font-style:italic;}.certify{font-size:25px;}.name{font-size:30px;color:green;}.fs-30{font-size:30px;}.fs-20{font-size:20px;}</style></head><body><div class='outer-border'><div class='inner-dotted-border'><span class='certification'>City of Dubbo Eisteddfod Inc. </span><br><br><span class='certify'><i>Third Prize</i></span><br><br><br><br><span class='name'><b>Awarded to ${certificate.awardedTo}</b></span><br/><br/><span class='certify'><i>for Class 7225-Jazz Solo</i></span> <br/><br/><span class='fs-30'>15 years and over</span> <br/><br/><span class='certify'><i>dated</i></span><br><span class='fs-30'>23 June,2022</span></div></div></body></html>`
    return html
}


function certifcateHTMLString(certificate) {

    return `
    <!DOCTYPE html>
<html>
  <head>
    <link href='https://fonts.googleapis.com/css?family=Great Vibes' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Merriweather' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=WindSong' rel='stylesheet'>
    <style>
      body,
      html {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .certificate-container {
        position: absolute;
        top: 35%;
        left: 28%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #2c624a;
      }

      .award-prize {
        font-size: 55px;
        font-family: 'Merriweather'serif;
        font-style: bold;
        color: #333136;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        top: 34%;
        left: ${certificate.certificateLayout.awardPrizeLeft}%;
        transform: translate(-50%, -50%);
      }

      .award-presented-to {
        font-size: 25px;
        font-family: 'Merriweather'serif;
        font-style: bold;
        color: #333136;
        overflow: hidden;
        text-align: center;
        position: absolute;
        top: 48%;
        left: 38%;
        transform: translate(-50%, -50%);
      }

      .award-name {
        font-size: ${certificate.certificateLayout.awardNameFontSize};
        font-family: 'Great Vibes';
        text-align: center;
        white-space: nowrap;
        position: absolute;
        text-align: center;
        top: 54%;
        left: ${certificate.certificateLayout.awardNameLeft}%;
        transform: translate(-50%, -50%);
      }

      .award-description {
        font-size: 20px;
        font-family: 'Merriweather';
        color: #333136;
        position: absolute;
        text-align: center;
        top: 67%;
        left: 30%;
        transform: translate(-50%, -50%);
      }

      .award-section {
        font-size: 20px;
        font-family: 'Merriweather';
        color: #333136;
        position: absolute;
        text-align: center;
        top: 71%;
        white-space: nowrap;
        overflow: hidden;
        left: ${certificate.certificateLayout.awardSectionLeft}%;
        transform: translate(-50%, -50%);
      }

      .date-input {
        font-size: 23px;
        font-family: 'Merriweather';
        color: #333136;
        margin-bottom: 5px;
        position: absolute;
        top: 83%;
        left: 32%;
        transform: translate(-50%, -50%);
      }

      .date-label {
        font-size: 20px;
        font-family: 'Merriweather';
        color: #333136;
        position: absolute;
        top: 87%;
        left: 36%;
        transform: translate(-50%, -50%);
      }

      .signature {
        font-size: 25px;
        font-family: 'WindSong';
        color: #333136;
        position: absolute;
        top: 82%;
        left: 56%;
        transform: translate(-50%, -50%);
      }

      .president {
        font-size: 20px;
        font-family: 'Merriweather';
        color: #333136;
        position: absolute;
        top: 87%;
        left: 59%;
        transform: translate(-50%, -50%);
      }
    </style>
  </head>
  <body>
    <img src='https://customerwebassets.hosted.enterprises/codes/images/dubbo-eisteddfod-certificate.png'>
    <div class='certificate-container'></div>
    <div class='award-prize'>${certificate.rank}</div>
    <div class='award-presented-to'>PROUDLY PRESENTED TO</div>
    <div class='award-name'>${certificate.awardedTo}</div>
    <div class='award-description'> This award is presented for the exemplary achievement in </div>
    <div class='award-section'>${certificate.awardedfor}</div>
    <div class='date-input'>${certificate.competitionDate}</div>
    <div class='date-label'>Date</div>
    <div class='signature'>B Redgrave</div>
    <div class='president'>President</div>
  </body>
</html>
    
`
}
