
// export const API_ENDPOINT = 'http://0.0.0.0:8055';
export const API_ENDPOINT = 'https://staging.backend.eisteddfod.hosted.enterprises';

export const TOKEN="RtKU1kZrqG7o6bI8mUiy9hr61N4dXWrH"

export const COMPEITION_FIELDS='?fields=*&fields=competitor.competitor_id,competitor.competitor_first_name&fields=section.section_id,section.section_desc,section.category_session&fields=section.category_session.category_session_id,section.category_session.session&fields=section.category_session.session.session_id,section.category_session.session.session_name&fields=section.category_session.category.category_id,section.category_session.category.category_name'
export const LIST_SECTION_FIELDS='?fields=section_id,section_code,section_desc,status&fields=category_session.category_session_id&fields=category_session.category.category_id,category_session.category.category_name&fields=category_session.session.session_id,category_session.session.session_name'



// API ENDPOINTS
export const ENDPOINT_ITEMS_COMPETITION='/items/competition'
export const ENDPOINT_ITEMS_COMPETITOR='/items/competitor'
export const ENDPOINT_ITEMS_CATEGORY='/items/category'
export const ENDPOINT_ITEMS_SESSION='/items/session'
export const ENDPOINT_CATEGORY_SESSION='/items/category_session'
export const ENDPOINT_ITEMS_SECTION='/items/section'



// Status Published
export const STATUS_PUBLISHED_JSON_OBJECT={"status": { "_eq": "published"}}



//SORT BY
// export const SORT_BY_SECTION=""

//LOADING MESSAGES
export const PLEASE_WAIT='Please wait...'

//ERROR MESSAGES
export const SOMETHING_WENT_WRONG ='Something went wrong. Please try again later.';
export const FIRST_NAME_ERROR ='First name is required.';
export const LAST_NAME_ERROR ='Last name is required.';
export const VALID_EMAIL_ERROR ='Please enter valid email address';
export const VALID_SECTION_DESCRIPTION_ERROR ='Please enter valid section description';
export const VALID_SECTION_CODE_ERROR ='Please enter valid section code';
export const VALID_CATEGORY_SELECT_ERROR ='Please select a category';
export const VALID_SESSION_SELECT_ERROR ='Please select a session';



//SUCCESS MESSAGES
export const SUCCESSFULLY_ADDED ='Successfully added';
export const SUCCESSFULLY_UPDATED ='Successfully updated';


// API FIELDS
//STATUS
export const STATUS ='status';
export const PUBLISHED ='published';


//COMPETITOR
export const COMPETITOR_FIRST_NAME ='competitor_first_name';
export const COMPETITOR_LAST_NAME ='competitor_last_name';
export const COMPETITOR_EMAIL ='competitor_email';
export const COMPETITOR_DOB ='competitor_dob';




//SECTION
export const SECTION_DESC ='section_desc';
export const SECTION_CODE ='section_code';


// First
// Second 
// Third
// Highly Commended


