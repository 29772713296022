import React, { Component } from 'react';

import { API_ENDPOINT,COMPEITION_FIELDS} from '../../utils/constants';
import '../../assets/css/table.css';


class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchQuery: '',
      loading: true,
      error: null,
    };
  }

  async componentDidMount() {
    try {
      const token = 'hqFKFYVISiMIjFTfHHPjt8PVAYRYZdAH';
      const base_url=API_ENDPOINT
      var endpoint='/items/competition'

      var filter=COMPEITION_FIELDS

      var url_to_fetch=base_url+endpoint+filter

      const response = await fetch(url_to_fetch, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      this.setState({ data, filteredData: data, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  handleSearch = async event => {
    const { value } = event.target;
    this.setState({ searchQuery: value });
    try {
      const response = await fetch(`https://example.com/api/data?q=${value}`);
      if (!response.ok) {
        throw new Error('Failed to fetch filtered data');
      }
      const filteredData = await response.json();
      this.setState({ filteredData });
    } catch (error) {
      this.setState({ error });
    }
  };

   handleDelete = (id) => {
    // const updatedItems = items.filter(item => item.id !== id);
    // setItems(updatedItems);
  };

  render() {
    const { filteredData, loading, error, searchQuery } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return (
      <div>
      <input type="text" placeholder="Search" value={searchQuery} onChange={this.handleSearch} />
      
      <table className="table">
        <thead>
          <tr>
            <th>Competition Date</th>
            <th>Category</th>
            <th>Session</th>
            <th>Section</th>

            <th>Competitor Name</th>
            <th>Rank</th>
            <th>Order</th>
            <th>Actions</th>




          </tr>
        </thead>
        <tbody>
          {filteredData.data.map(competition => (
            <tr key={competition.competition_id}>
              <td>{competition.competition_date}</td>
              <td>{competition.section.category_session.session.session_name}</td>
              <td>{competition.section.category_session.category.category_name}</td>
              <td>{competition.section.section_desc}</td>
              <td>{competition.competitor.competitor_first_name}</td>
              <td>{competition.rank}</td>
              <td>{competition.order_no}</td>

              <td>
                <div>
                <button className="delete-button" onClick={() => this.handleDelete(competition.competition_id)}>Delete</button>

                </div>
                <div>
                <button className="delete-button" onClick={() => this.handleDelete(competition.competition_id)}>Update</button>
                </div>

              {/* Add additional actions here */}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      </div>

    );
  }
}

export default Table;
