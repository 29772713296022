import React from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../assets/css/navbar.css";

class Navbar extends React.Component {
  render() {
    const { isAuthenticated } = this.props;
    const { onLogout } = this.props;

    return (
      <nav className="navbar">
        <div>
          <img
            src="https://images.squarespace-cdn.com/content/v1/5e850d7ecebf196b1ec5897f/e6b6b6f4-2c65-4062-94ee-3612899943ed/DE+NEW+LOGO-03.jpg?format=1500w"
            alt="Logo"
          />
          {/* <span>City Eisteddfod</span> */}
        </div>

        <ul>
          {isAuthenticated ? (
            <>
              <li>
                <Link to="/">Home</Link>{" "}
              </li>
              <li>
                <Link to="/oneoff">One Off</Link>
              </li>
              <li>
                <Link to="/upload/csv">Upload</Link>
              </li>

              <li onClick={onLogout}>
                <Link to="/login">Logout</Link>
              </li>
            </>
          ) : (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}

          {/* <li>
            <Link to="/competition">Competition</Link>
          </li> */}

          {/* <li>
            <Link to="/add/competitor">Competitor</Link>
          </li>
          <li>
            <Link to="/add
            /section">Section</Link>
          </li> */}
          {/* 
{!isAuthenticated&& (
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  
                )} */}

          {/* <li>
            <Link to="/login">Login</Link>
          </li> */}
          {/* 
      {isAuthenticated&& (
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  
                )} */}

          {/* <li>
            <Link to="/">Home</Link>
          </li> */}
          {/* {isAuthenticated&& (

          <li>
            <Link to="/upload/csv">Upload</Link>
          </li>
          )}

{isAuthenticated&& (
         
          <li>
            <Link to="/logout">Logout</Link>
          </li>
          )} */}

          {/* <li>
            <a href="#">
              <i className="fas fa-user"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-sign-in-alt"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-sign-out-alt"></i>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
