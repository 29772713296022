import ReactDOM from "react-dom";

import "./../../assets/css/modal.css"

const LoadingModal = () => {
    
    return ReactDOM.createPortal(
        <>
          <div className="modal-overlay" />
          <div className="modal-wrapper">
            <div className="modal">
            <div className="spinner"></div>
            <p className="loading-message">Getting Certificate Ready. Please wait...</p>
            </div>
          </div>
        </>,
        document.body
      );
  }

  export default LoadingModal;