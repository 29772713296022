
import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, PAGE_LIMIT, API_FIELDS_STATUS, API_FIELDS_COMPETITOR } from '../../utils/constants/api_constants';
import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';



class CompetitorRepository {


  async getCompetitorById(competitorId) {
    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.COMPETITOR}/${competitorId}`);
    const competitorData = response.data;
    const competitor = Mapper.mapToCompetitorModel(competitorData);
    return competitor
  }

  async getAllCompetitors() {
    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.COMPETITOR}`);
    const competitorsData = response.data;

    const competitorsDataModel = []

    for (let i = 0; i < competitorsData.length; i++) {
      competitorsDataModel.push(Mapper.mapToCompetitorModel(competitorsData[i]));
    }

    return competitorsDataModel;
  }


  async getAllCompetitorsWithName(name) {

    var response = null
    if (!!name) {
      const paramsWithName = JSON.stringify(Params.fetchCompetitorsByName(name))
      response = await apiClient.get(`${API_ENDPOINTS_ITEMS.COMPETITOR}?limit=${PAGE_LIMIT.DEFAULT}&filter=${paramsWithName}`);
    } else {
      const paramsWithStatus = JSON.stringify(Params.fetchStatusParams)
      response = await apiClient.get(`${API_ENDPOINTS_ITEMS.COMPETITOR}?limit=${PAGE_LIMIT.DEFAULT}&filter=${paramsWithStatus}`);
    }

    const competitorsData = response.data;

    const competitorsDataModel = []

    for (let i = 0; i < competitorsData.length; i++) {
      competitorsDataModel.push(Mapper.mapToCompetitorModel(competitorsData[i]));
    }

    return competitorsDataModel;
  }

  async getCompetitorsByNameAndEmail(name, email) {
    console.log("Name is:",name)
    const nameencodedString = name.replace(/&/g, "%26");
    const filterParamsforCompetitor = JSON.stringify(Params.fetchCompetitorsByNameAndEmail(nameencodedString, email))

    const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.COMPETITOR}?filter=${filterParamsforCompetitor}`);
    const competitorsData = response.data;
    const competitorsDataModel = []
    for (let i = 0; i < competitorsData.length; i++) {
      competitorsDataModel.push(Mapper.mapToCompetitorModel(competitorsData[i]));
    }
    return competitorsDataModel
  }

  async createCompetitor(competitorModel) {

    const competitorModelJsonObject = {
      [API_FIELDS_STATUS.STATUS]: API_FIELDS_STATUS.PUBLISHED,
      [API_FIELDS_COMPETITOR.COMPETITOR_NAME]: competitorModel.competitorName,
      [API_FIELDS_COMPETITOR.COMPETITOR_EMAIL]: competitorModel.competitorEmail,
    };


    if (competitorModel.competitorDob!=null) {
      competitorModelJsonObject[API_FIELDS_COMPETITOR.COMPETITOR_DOB] = competitorModel.competitorDob
    }


    const response = await apiClient.post(`${API_ENDPOINTS_ITEMS.COMPETITOR}`, competitorModelJsonObject);
    const competitorData = response.data;
    const competitor = Mapper.mapToCompetitorModel(competitorData);
    return competitor
  }

  async createCompetitorWithCompetitorExistsCheck(competitorModel) {
    let competitorModelResponse = competitorModel

    try {
      const competitorData = await this.getCompetitorsByNameAndEmail(competitorModel.competitorName, competitorModel.competitorEmail);

      if (competitorData.length === 0) {
        competitorModelResponse = await this.createCompetitor(competitorModel);
        console.log('Competitor does not exist in database. Created Competitor now and got this response:', competitorModelResponse);
      }

      else {
        competitorModelResponse = competitorData[0]
        console.log(`Competitor Name: ${competitorModelResponse.competitorName} exist in database. Competitor Id is ${competitorModelResponse.competitorId}`);
      }
    }

    catch (error) {
      throw new Error(`Error while creating Competitor: ${error}`);

    }

    return competitorModelResponse
  }

  async updateUser(userId, userData) {
    const response = await apiClient.put(`users/${userId}`, userData);
    return response.data;
  }

  async deleteUser(userId) {
    const response = await apiClient.delete(`users/${userId}`);
    return response.data;
  }
}

export default CompetitorRepository;
