class Certificate {


    constructor(awardedTo = null, awardedfor = null, competitionDate = null,rank=null,certificateLayout=null) {

        this.awardedTo = awardedTo;
        this.awardedfor = awardedfor;
        this.competitionDate = competitionDate;
        this.rank = rank;
        this.certificateLayout=certificateLayout
    }


}

export default Certificate;