import { API_BASE_URL } from "../../utils/constants/api_constants";
import LocalStorageService from "../internal/LocalStorageService";
import { getAccessTokenExpiryDateTime } from "../../utils/dateUtils";

const localStorageService = LocalStorageService.getService();

class AuthService {
  async login(email, password) {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      console.log("Error: ", response.status);
      throw new Error("Invalid username or password");
    }

    const jsonResponse = await response.json();
    const data = jsonResponse.data;

    const { access_token, expires, refresh_token } = data;

    const expiresIn = getAccessTokenExpiryDateTime(expires);

    localStorageService.setAccessToken(access_token);
    localStorageService.setRefreshToken(refresh_token);
    localStorageService.setATExpiresIn(expiresIn);
  }

  async logout() {
    localStorageService.removeAccessToken();
    localStorageService.removeRefreshToken();
    localStorageService.removeATExpiresIn();
  }

  getAccessToken() {
    return localStorageService.getAccessToken();
  }

  getRefreshToken() {
    return localStorageService.getRefreshToken();
  }

  getAccessTokenExpiry() {
    return localStorageService.getATExpiresIn();
  }

  isAuthenticated() {
    const token = localStorageService.getAccessToken();
    return !!token;
  }

  async refreshAccessToken() {
    const refreshToken = localStorageService.getRefreshToken();
    const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refresh_token: refreshToken, mode: "json" }),
    });

    if (!response.ok) {
      throw new Error("Error in Refresh token");
    }

    const jsonResponse = await response.json();
    const data = jsonResponse.data;

    const { accessToken } = response;
    localStorageService.setItem("accessToken", accessToken);
    return accessToken;
  }
}

const authService = new AuthService();
export default authService;

// export default new AuthService();
