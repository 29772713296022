
class Section {
    constructor(sectionId = null, sectionCode = null, sectionDesc = null, status = null, dateCreated = null, dateUpdated = null) {
        this.sectionId = sectionId;
        this.sectionCode = sectionCode;
        this.sectionDesc = sectionDesc;
        this.status = status
        this.dateCreated = dateCreated
        this.dateUpdated = dateUpdated
    }

    getSectionId() {
        return this.sectionId;
    }
    setSectionId(sectionId) {
          this.sectionId = sectionId;
    }

}

export default Section;
