import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, DEFAULT_PAGE_LIMIT } from '../../utils/constants/api_constants';
import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import FieldParams from '../models/params/FieldParams';
import authService from '../../infrastucture/external/AuthService';


class AuthRepository {

  //   async getParticipatesInById(participatesInId) {
  //     const fields = FieldParams.fetchFieldsForParticipatesIn()
  //     const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}/${participatesInId}?fields=${fields}`);
  //     const participatesInData = response.data;
  //     const participatesInById = Mapper.mapToParticipatesInModel(participatesInData);
  //     return participatesInById
  //   }

  async login(email, password) {
    return authService.login(email, password);
  }

  async logout() {
    const refreshToken = authService.getRefreshToken();
    if (refreshToken && (refreshToken!=null)) {

      const logoutObject = {
        "refresh_token": refreshToken
      };

      try {
        const response = await apiClient.post(`auth/logout`, logoutObject);

      }
      catch (error) {
        authService.logout();
      }
    }
    authService.logout();
  }

  async isAuthenticated() {
    return authService.isAuthenticated();
  }

  async getAccessToken() {
    return authService.getAccessToken();
  }

  async refreshAccessToken() {
    return authService.refreshAccessToken();
  }



}

export default AuthRepository;