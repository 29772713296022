// src/api/apiClient.js

import { API_BASE_URL } from "../utils/constants/api_constants";
import LocalStorageService from "../infrastucture/internal/LocalStorageService";
import { getAccessTokenExpiryDateTime } from '../utils/dateUtils';
import UnauthorizedError from '../domain/models/UnauthorizedError'

const localStorageService = LocalStorageService.getService();



class ApiClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  async sendRequest(endpoint, method = "GET", body = null) {
    const headers = {
      "Content-Type": "application/json",
    };
    const token = localStorageService.getAccessToken();
    headers["Authorization"] = `Bearer ${token}`;

    const options = {
      method: method.toUpperCase(),
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    };

    console.log("Url to send is " + `${this.baseURL}/${endpoint}`);

    const response = await fetch(`${this.baseURL}/${endpoint}`, options);

    if (response.status == 204) {
      return;
    }
    if (response.status == 401) {
      const refreshToken = await this.refreshAccessToken();
      if (refreshToken) {
        const data = this.reSendRequest(endpoint, method, body)
        return data;
      }
      else {
        throw new UnauthorizedError('Unauthorized access');
      }
    }
    if (!response.ok) {
      console.error("Error in Api Client Status:", response.status);
      throw new Error(`Request failed: ${response.status}`);
    }

    const data = await response.json();
    return data;
  }

  async reSendRequest(endpoint, method = "GET", body = null) {
    const headers = {
      "Content-Type": "application/json",
    };
    const token = localStorageService.getAccessToken();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    else {
      throw new UnauthorizedError('Unauthorized access');
    }

    const options = {
      method: method.toUpperCase(),
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(`${this.baseURL}/${endpoint}`, options);

    if (!response.ok) {
      throw new Error(`Request failed: ${response.status}`);
    }
    if (response.status == 204) {
      return;
    }
    if (response.status == 401) {
      throw new UnauthorizedError('Unauthorized access');
    }
    const data = await response.json();
    return data;
  }

  async get(endpoint) {
    return this.sendRequest(endpoint, "GET");
  }

  async post(endpoint, body) {
    return this.sendRequest(endpoint, "POST", body);
  }

  async put(endpoint, body) {
    return this.sendRequest(endpoint, "PUT", body);
  }

  async patch(endpoint, body) {
    return this.sendRequest(endpoint, "PATCH", body);
  }

  async delete(endpoint) {
    return this.sendRequest(endpoint, "DELETE");
  }

  async refreshAccessToken() {
    const refreshToken = localStorageService.getRefreshToken();
    if (refreshToken !== null) {
      const refreshTokenBody = {
        refresh_token: refreshToken,
        mode: "json",
      };

      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(refreshTokenBody),
      };
      const endpoint = "auth/refresh";
      const response = await fetch(`${this.baseURL}/${endpoint}`, options);
      if (response.status == 401) {
        return false
      }
      if (response.ok) {
        const jsonResponse = await response.json();
        const data = jsonResponse.data;
        const { access_token, expires, refresh_token } = data;
        const expiresIn = getAccessTokenExpiryDateTime(expires)
        localStorageService.setAccessToken(access_token);
        localStorageService.setRefreshToken(refresh_token);
        localStorageService.setATExpiresIn(expiresIn);
        return true
      }
      else {
        return false
      }
    }
    return false
  }
}

const apiClient = new ApiClient(API_BASE_URL);
export default apiClient;
