
import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, PAGE_LIMIT, API_FIELDS_SECTION, API_FIELDS_STATUS } from '../../utils/constants/api_constants';
import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import FieldParams from '../models/params/FieldParams';



class SectionRepository {

    async getSectionById(sectionId) {
        const fields = FieldParams.fetchFieldsForSection()
        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.SECTION}/${sectionId}?fields=${fields}`);
        const sectionData = response.data;
        const sectionDataModel = Mapper.mapToSectionModel(sectionData);
        return sectionDataModel
    }

    async getAllSections() {
        const fields = FieldParams.fetchFieldsForSection()
        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.SECTION}?fields=${fields}`);
        const sectionData = response.data;

        const sectionsDataModel = []

        for (let i = 0; i < sectionData.length; i++) {
            sectionsDataModel.push(Mapper.mapToSectionModel(sectionData[i]));
        }

        return sectionsDataModel;
    }


    async getAllSectionsWithFilter(sectionField) {

        const filterParamsforSection = JSON.stringify(Params.fetchSectionFilterParams(sectionField))

        const fields = FieldParams.fetchFieldsForSection()
        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.SECTION}?limit=${PAGE_LIMIT.DEFAULT}&fields=${fields}&filter=${filterParamsforSection}`);

        // const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.PARTICIPATES_IN}?limit=${PAGE_LIMIT}&fields=${fields}&filter=${filterParamsforParticipates}`);
        const sectionData = response.data;

        const sectionsDataModel = []

        for (let i = 0; i < sectionData.length; i++) {
            sectionsDataModel.push(Mapper.mapToSectionModel(sectionData[i]));
        }

        return sectionsDataModel
    }

    async getSectionsBySectionCode(sectionCode) {

        const filterParamsforSection = JSON.stringify(Params.fetchSectionFilterParamsToCheckSectionCodeExists(sectionCode))

        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.SECTION}?filter=${filterParamsforSection}`);
        const sectionData = response.data;
        const sectionsDataModel = []

        for (let i = 0; i < sectionData.length; i++) {
            sectionsDataModel.push(Mapper.mapToSectionModel(sectionData[i]));
        }
        return sectionsDataModel
    }


    async createSection(sectionModel) {
        const sectionModelJsonObject = {
            [API_FIELDS_STATUS.STATUS]: API_FIELDS_STATUS.PUBLISHED,
            [API_FIELDS_SECTION.SECTION_CODE]: sectionModel.sectionCode,
            [API_FIELDS_SECTION.SECTION_DESC]: sectionModel.sectionDesc
        };

        const response = await apiClient.post(`${API_ENDPOINTS_ITEMS.SECTION}`, sectionModelJsonObject);
        const sectionData = response.data;
        const sectionModelResponse = Mapper.mapToSectionModel(sectionData);
        return sectionModelResponse
    }

    async createSectionWithSectionExistsCheck(sectionModel) {
        let sectionModelResponse=sectionModel
        
        try {
            const sectionData = await this.getSectionsBySectionCode(sectionModel.sectionCode);
            if (sectionData.length === 0) {
                const sectionModelCreateResponse = await this.createSection(sectionModel);
                sectionModelResponse=sectionModelCreateResponse
            }

            else {
                sectionModelResponse=sectionData[0]
            }
        }

        catch (error) {
            throw new Error(`Error while creating Section: ${error}`);

        }

        return sectionModelResponse
    }
}

export default SectionRepository;
