import React, { useState, useEffect } from "react";


import * as Constants from '../../utils/constants';
import { validateEmail, validateName, getTodayDate } from '../../utils/validation';

import { useParams, useNavigate } from 'react-router-dom';


import '../../assets/css/form.css';


function UpdateCompetitor() {
    const { competitorId } = useParams();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [email, setEmail] = useState("");
    const [isArchived, setIsArchived] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setError("");
        setSuccess("")

        try {

            const errors = [];

            if (!validateName(firstName)) {
                errors.push(Constants.FIRST_NAME_ERROR);
            }
            else if (!validateName(lastName)) {
                errors.push(Constants.LAST_NAME_ERROR);
            }
            else if (!validateEmail(email)) {
                errors.push(Constants.VALID_EMAIL_ERROR);

            }

            if (errors.length > 0) {
                setError(errors);
                setIsSubmitting(false);
            } else {
                // submit form data

                var updateCompetitorURL = Constants.API_ENDPOINT + Constants.ENDPOINT_ITEMS_COMPETITOR + '/' + competitorId
                var post_request = JSON.stringify(
                    {
                        'status': Constants.PUBLISHED,
                        'competitor_first_name': firstName,
                        'competitor_last_name': lastName,
                        'competitor_email': email,
                        'competitor_dob': dob.trim() === "" ? null : dob,
                        'status':isArchived?'archived':'published'
                    })

                 
                console.log(post_request)

                const response = await fetch(updateCompetitorURL, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Constants.TOKEN}`

                    },
                    body: post_request,
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    setIsSubmitting(false);
                    setSuccess(Constants.SUCCESSFULLY_UPDATED)

                } else {
                    setError(Constants.SOMETHING_WENT_WRONG);
                    setIsSubmitting(false);
                }
            }
        } catch (error) {
            console.error(error);
            setIsSubmitting(true);
            setError(Constants.SOMETHING_WENT_WRONG);
        }
    };

    useEffect(() => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${Constants.TOKEN}`);

        var competitorByIdURL = Constants.API_ENDPOINT + Constants.ENDPOINT_ITEMS_COMPETITOR + '/' + competitorId
        console.log(competitorByIdURL)


        const fetchCompetitorDetails = async () => {
            const response = await fetch(competitorByIdURL, { headers });
            const competitorByIdData = await response.json();
            console.log(competitorByIdData.data.competitor_dob)
            setFirstName(competitorByIdData.data.competitor_first_name)
            setLastName(competitorByIdData.data.competitor_last_name)
            setEmail(competitorByIdData.data.competitor_email)
            setDob(competitorByIdData.data.competitor_dob)
            if (competitorByIdData.data.status!='published'){
                setIsArchived(true)
            }

            // const [firstName, setFirstName] = useState("");
            // const [lastName, setLastName] = useState("");
            // const [dob, setDob] = useState("");
            // const [email, setEmail] = useState("");
        };

        fetchCompetitorDetails()

    }, []);


    return (
        <div>
            <h2>Update Competitor Details</h2>

            <form className="form-container" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label>
                        First Name
                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </label>
                </div>

                <div className="form-group">
                    <label>
                        Last Name
                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </label>
                </div>

                <div className="form-group">
                    <label>
                        Email
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>
                </div>

             

                <div className="form-group">
                    <label>
                        Date of Birth
                        <input type="date" max={getTodayDate()} value={dob} onChange={(e) => setDob(e.target.value)} />
                    </label>
                </div>

                <div className="form-group">
                    <label>
                        Archived
                        <input type="checkbox" checked={isArchived} onChange={(e) => setIsArchived(e.target.checked)} />
                    </label>
                </div>

                <div className="form-group">

                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Please wait...' : 'Update'}
                    </button>

                </div>
                {isSubmitting && <div className="spinner"></div>}


                {error && <p className="error-message ">{error}</p>}
                {success && <p className="success-message ">{success}</p>}


            </form>
        </div>
    );
}

export default UpdateCompetitor;
