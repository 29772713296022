import React, { useState, useEffect, useRef } from "react";

import Select from 'react-select';
import CompetitorRepository from '../../domain/repositories/CompetitorRepository';
import ParticipatesInRepository from '../../domain/repositories/ParticipatesInRepository';
import SectionRepository from '../../domain/repositories/SectionRepository';
import CertificateRepository from '../../domain/repositories/CertificateRepository';
import Certificate from '../../domain/models/CertificateModel';
import { getCurrentDateAndTimeinAuFormat } from '../../utils/dateUtils';
import { getRank } from '../../utils/commonUtils';
import Mapper from '../../domain/models/mapper/Mappers';
import UnauthorizedError from '../../domain/models/UnauthorizedError'

import { PAGE_LIMIT } from '../../utils/constants/api_constants';

import LoadingModal from './LoadingModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import '../../assets/css/table.css';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


const Home = ({ onLogout }) => {

  const navigate = useNavigate();

  const [sectionOptions, setSectionOptions] = useState([]);
  const [sectionSelectedOptions, setSectionSelectedOptions] = useState([]);


  const [competitorNameOptions, setCompetitorNameOptions] = useState([]);
  const [competitorNameSelectedOptions, setCompetitorsNameSelectedOptions] = useState(null);


  const [nameInputValue, setNameInputValue] = useState('');
  const [namePlaceHolder, setNamePlaceholder] = useState('Name');

  const [sectionInputValue, setSectionInputValue] = useState('');
  const [sectionPlaceholder, setSectionPlaceholder] = useState('Section');


  const [competitionData, setCompetitionData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.PARTICIPATES_IN);
  const [totalPages, setTotalPages] = useState(0);
  const [competitionTableFilter, setCompetitionTableFilter] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);


  const [sendingEmail, setSendingEmail] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);


  const currentDate = new Date()

  const lastYearDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
  const nextYearDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, currentDate.getDate());


  const [startDate, setStartDate] = useState(lastYearDate.toLocaleDateString('en-AU')); // set to last year's date with today's month and year
  const [endDate, setEndDate] = useState(nextYearDate.toLocaleDateString('en-AU')); // set to today's date


  const [rankSelectedOption, setRankSelectedOption] = useState(1);
  const [rankOptions, setRankOptions] = useState(getRank());

  const [keyUsedToClearNameSelect, setKeyUsedToClearNameSelect] = useState(200);
  const [keyUsedToClearSectionSelect, setKeyUsedToClearSectionSelect] = useState(0);


  const [dateRange, setDateRange] = useState({
    startDate: currentDate,
    endDate: currentDate,
    key: 'selection'
  });

  const [showPicker, setShowPicker] = useState(false);


  const competitorRepository = new CompetitorRepository();
  const participatesInRepository = new ParticipatesInRepository();
  const sectionRepository = new SectionRepository();
  const certificateRepository = new CertificateRepository();


  const handleDateRangeChange = (ranges) => {
    setDateRange(ranges.selection);
  };

  const handleDateRangeDoneClick = () => {
    setShowPicker(false);
    const startDate = dateRange.startDate.toLocaleDateString();
    const endDate = dateRange.endDate.toLocaleDateString();
    setStartDate(startDate)
    setEndDate(endDate)
    setCompetitionTableFilter(!competitionTableFilter);
    setPage(1)
  };

  const handleDateRangeButtonClick = () => {
    setShowPicker(!showPicker);
  };


  const handleSectionSelectedOptions = sectionSelectedOptions => {
    setSectionSelectedOptions(sectionSelectedOptions);
    //fetch in participates table for call compeittion with competition id
    setCompetitionTableFilter(!competitionTableFilter);
    setPage(1)

  };


  const handleCompetitorsNameSelectedOptions = competitorNameSelectedOptions => {
    setCompetitorsNameSelectedOptions(competitorNameSelectedOptions);

    setPage(1)

    //fetch in participates table for call compeittion with competition id
    setCompetitionTableFilter(!competitionTableFilter);

  };


  const handleSectionSelectChange = async (newSectionValue) => {
    setSectionOptions([]);

    setSectionInputValue(newSectionValue);

    try {
      const sectionData = await sectionRepository.getAllSectionsWithFilter(newSectionValue);

      const newOptions = sectionData.map(item => ({
        value: item.sectionId,
        label: item.sectionCode + ' - ' + item.sectionDesc,
      }));
      setSectionOptions(newOptions);


    } catch (error) {
      setSectionOptions([])

    }

  };



  const handleNameChange = async (newValue) => {
    setCompetitorNameOptions([]);

    setNameInputValue(newValue);


    if (newValue.length > 0) {

      try {
        const competitorData = await competitorRepository.getAllCompetitorsWithName(newValue)

        const newOptions = competitorData.map(item => ({
          value: item.competitorId,
          label: item.competitorName,
        }));
        setCompetitorNameOptions(newOptions);


      } catch (error) {
        setCompetitorNameOptions([])
      }

    }
    else {
      setCompetitorNameOptions([]);
    }


  };


  async function handleRankOptionsChange(event, rowIndex, key) {
    // Update selected option state
    setRankSelectedOption(event.target.value);
    const participatesInModel = key

    const selectedRankOption = rankOptions.find((option) => option.key === event.target.value);
    const newRankKey = selectedRankOption.key

    if (newRankKey && (newRankKey != 'empty')) {

      try {

        const participateUpdateData = await participatesInRepository.updateParticipatesInRankWithCheck(participatesInModel, newRankKey);

        const newCompetitionData = [...competitionData];
        newCompetitionData[rowIndex].rank = participateUpdateData.rank;
        setCompetitionData(newCompetitionData);

      } catch (error) {
        console.log("Error while updating placing", error)
        toast.error(new Error(`${error}`).message)
      }
    }
  }

  const handleClear = () => {
    setSectionOptions([])
    setSectionSelectedOptions([])

    setCompetitorNameOptions([])
    setCompetitorsNameSelectedOptions([])

    setSectionInputValue('')
    setNameInputValue('')

    setNamePlaceholder('Name')
    setSectionPlaceholder('Section')


    setKeyUsedToClearSectionSelect(keyUsedToClearSectionSelect + 1)
    setKeyUsedToClearNameSelect(keyUsedToClearNameSelect + 1)

    // setErrorMessage("Handle Clear")
    setIsError(false)
    setShowPicker(false);



    // const currentDate = new Date()
    // const lastYearDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
    // const nextYearDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, currentDate.getDate());

    // setStartDate(lastYearDate.toLocaleDateString('en-AU'))
    // setEndDate(nextYearDate.toLocaleDateString('en-AU'))


    // setDateRange({
    //   startDate: currentDate,
    //   endDate: currentDate,
    //   key: 'selection'
    // })
    setPage(1)
    setPageLimit(PAGE_LIMIT.PARTICIPATES_IN)
    setCompetitionTableFilter(!competitionTableFilter);
  }



  useEffect(() => {

    const fetchSectionOptions = async () => {


      try {

        const sectionData = await sectionRepository.getAllSectionsWithFilter('');
        const newOptions = sectionData.map(item => ({
          value: item.sectionId,
          label: item.sectionCode + ' - ' + item.sectionDesc,
        }));
        setSectionOptions(newOptions);


      } catch (error) {
        console.error("Section Repository Error:", error);
        setSectionOptions([])
      }

    };

    fetchSectionOptions()

    //End Section Select Options


    async function testApiCall() {
      try {
        const testApiCallResponse = await sectionRepository.getAllSectionsWithFilter('');
        console.log("Test Api Call Data", testApiCallResponse)
      } catch (error) {
        console.error("Test Api Call Data Error:", error);
      }
    }

    // testApiCall()
    // const fetchSectionOptions = async () => {


    const fetchInitialParticipations = async () => {
      try {

        let sectionIdToFetch = null
        let competitorsIdToFetch = null

        if (sectionSelectedOptions !== null && sectionSelectedOptions !== undefined) {
          sectionIdToFetch = sectionSelectedOptions.value
        }

        if (competitorNameSelectedOptions !== null && competitorNameSelectedOptions !== undefined) {
          competitorsIdToFetch = competitorNameSelectedOptions.value

        }


        const participatesInData = await participatesInRepository.getAllParticipatesInWithFilter(startDate, endDate, sectionIdToFetch, competitorsIdToFetch, page, pageLimit)

        const totalCount = participatesInData.filterCount
        const totalPages = totalCount / pageLimit
        setTotalPages(totalPages);
        setCompetitionData(participatesInData.participatesInDataModel)
      } catch (error) {
        console.error("Fetch All Particiations Error", error)
        if (error instanceof UnauthorizedError) {
          toast.error(new Error(`${error}`).message);
          onLogout();
        }
      }
    }

    fetchInitialParticipations()


  }, [page, pageLimit, competitionTableFilter]);

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const handlePageLimitChange = newPageLimit => {
    setPage(1);
    setPageLimit(newPageLimit);
  };

  const handleCompetitorNameClicked = (competitorId) => {
    console.log("Competitor", competitorId)
    navigate(`/update/competitor/${competitorId}`);
  };




  const sendEmail = (id) => {
    console.log("Sending Please wait...")
    setSendingEmail(true)
  };

  const printCertificate = async (participation) => {
    const rank = Mapper.mapUserDisplayRankToDb(participation.rank)
    if (rank==0){
      toast.info(`You must select placing first before you print the certificate`)
      return
    }

    setIsPrinting(true)
    try {
      const competitorName = participation.competitor.competitorName
      const rank = Mapper.mapUserDisplayRankToDb(participation.rank)
      const sectionCode = participation.competition.section.sectionCode
      const sectionDesc = participation.competition.section.sectionDesc
      const awardedFor = `Class ${sectionCode} - ${sectionDesc}`
      const competitionDate = participation.competition.competitionDate
      const certificateData = new Certificate(competitorName, awardedFor, competitionDate, rank)
      const certificateResponseData = await certificateRepository.generateCertificateFromAWSLambda(certificateData)


      const pdfURL = certificateResponseData.pdf_uri
      if (pdfURL) {
        window.open(pdfURL, "_blank");
      }
      setIsPrinting(false)
    } catch (error) {
      setIsPrinting(false)
      toast.error(new Error(`Certificate Error: ${error}`).message)
    }


  };




  return (
    <div>
      <h2>Participants</h2>
      <div>


        {/* Start Date Range Picker */}
        <div>


          <button className="date-button" onClick={handleDateRangeButtonClick}>Select Date</button>

          {/* {showPicker && (<button className="date-range-picker" style={{ margin: '10,10px' }} onClick={handleDateRangeDoneClick}>Done</button>)} */}


          {/* <button className="date-range-picker" style={{ margin: '10,10px' }} onClick={handleDateRangeDoneClick}>Done</button> */}


          {showPicker && (

            <DateRangePicker
              ranges={[dateRange]}
              onChange={handleDateRangeChange}
            />

          )}





          <p className="date-range-picker__selected-date-range">
            Selected Date Range: {startDate} - {endDate}
          </p>

          {showPicker && (<button className="date-button" style={{ marginBottom: '20px' }} onClick={handleDateRangeDoneClick}>Done</button>)}


        </div>



        {/* End Date Range Pciker */}




        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>




            {/* <Select placeholder="Category" value={categorySelectedOptions} options={categoryOptions.map(item => ({ label: item.category_name, value: item.category_id }))} onChange={handleCategorySelectChange} />
                        <Select placeholder="Session" value={sessionSelectedOptions} options={sessionOptions.map(item => ({ label: item.session_name, value: item.session_id }))} onChange={handleSessionSelectChange} /> */}
            {/* <Select placeholder="Section" value={sectionSelectedOptions} options={sectionOptions.map(item => ({ label: item.sectionDesc, value: item.sectionId }))} onChange={handleSectionSelectChange} /> */}


            <Select
              key={keyUsedToClearSectionSelect}

              placeholder={sectionPlaceholder}
              inputValue={sectionInputValue}
              onInputChange={handleSectionSelectChange}
              options={sectionOptions}
              onChange={handleSectionSelectedOptions}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  width: 300,
                }),
                option: (provided) => ({
                  ...provided,
                  textAlign: 'left',
                }),
              }}
            />


            <Select
              key={keyUsedToClearNameSelect}
              placeholder={namePlaceHolder}
              inputValue={nameInputValue}
              onInputChange={handleNameChange}
              options={competitorNameOptions}
              onChange={handleCompetitorsNameSelectedOptions}
              styles={{
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  display: 'none',
                }),
                control: (provided, state) => ({
                  ...provided,
                  width: 200,
                }),
                option: (provided) => ({
                  ...provided,
                  textAlign: 'left',
                }),
              }}
            />



            <button className="date-button" onClick={handleClear}>Clear Filter</button>




          </div>
        </div>


      </div>

      <div>

        <br></br>
        <br></br>


      </div>



      {/* <div>
                <button className="search-button" onClick={handleSearch}>
                    Search
                </button>

            </div> */}

      <ToastContainer />


      <div class="table-container" >

        {competitionData.length > 0 ? (


          <table className="table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Competition Date</th>
                {/* <th>Competition Status</th> */}

                <th>Section</th>
                <th>Section Desc</th>

                <th>Competitor Name</th>
                <th>Order</th>
                <th>Placing</th>
                <th>Actions</th>


              </tr>
            </thead>



            <tbody>
              {competitionData.map((participation, index) => (
                <tr key={participation.participatesInId}>
                  <td>{index + 1}</td>
                  <td>{participation.competition.competitionDate}</td>
                  {/* <td>{participation.competition.competitionStatus}</td> */}

                  <td>{participation.competition.section.sectionCode}</td>
                  <td>{participation.competition.section.sectionDesc}</td>
                  <td className="highlight-column">
                    {participation.competitor.competitorName}

                  </td>
                  {/* <td className="highlight-column" onClick={() => handleCompetitorNameClicked(participation.competitor.competitorId)}>
                    {participation.competitor.competitorName}

                  </td> */}
                  <td>{participation.orderNo}</td>


                  <td>
                    {participation.rank}


                    <select style={{ margin: '0 10px' }}
                      // value={rankSelectedOption}
                      onChange={event => handleRankOptionsChange(event, index, participation)}>
                      {rankOptions.map((rankOption) => (
                        <option key={rankOption.key} value={rankOption.key}>
                          {rankOption.value}
                        </option>

                        //   <option key={rankOptions.key} value={rankOptions.key}>
                        //   {rankOptions.value}
                        // </option>
                      ))}

                    </select>



                  </td>

                  <td>
                    {/* <button className="delete-button" disabled={sendingEmail}
                                            onClick={() => sendEmail(participation.participatesInId)}>Email</button> */}

                    <button className="delete-button" disabled={isPrinting}
                      onClick={() => printCertificate(participation)}>Print</button>
                    {isPrinting && <LoadingModal />}


                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        ) : (

          <table className="table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Competition Date</th>
                <th>Competition Status</th>

                <th>Section</th>
                <th>Section Desc</th>

                <th>Competitor Name</th>
                <th>Order</th>
                <th>Rank</th>
                <th>Actions</th>


              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="9" style={{ textAlign: 'center' }} >No data available</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <div style={{ marginTop: '15px' }}>
        <button disabled={page === 1} onClick={() => handlePageChange(page - 1)}>
          Previous
        </button>
        {Array.from({ length: totalPages }).map((_, index) => (
          <button key={index} onClick={() => handlePageChange(index + 1)} disabled={page === index + 1}>
            {index + 1}
          </button>
        ))}
        <button disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>
          Next
        </button>
        <select value={pageLimit} onChange={event => handlePageLimitChange(parseInt(event.target.value, 10))}>
          <option value={PAGE_LIMIT.PARTICIPATES_IN}>{PAGE_LIMIT.PARTICIPATES_IN}</option>
          <option value={PAGE_LIMIT.PARTICIPATES_IN_INCREMENT_100}>{PAGE_LIMIT.PARTICIPATES_IN_INCREMENT_100}</option>
          <option value={PAGE_LIMIT.PARTICIPATES_IN_INCREMENT_200}>{PAGE_LIMIT.PARTICIPATES_IN_INCREMENT_200}</option>
        </select>
      </div>


    </div>
  );
}

export default Home;