
import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, PAGE_LIMIT, API_FIELDS_PARTICIPATES_IN } from '../../utils/constants/api_constants';
import CsvUpload from '../models/CsvUploadModel';
import Section from '../models/SectionModel';
import Competition from '../models/CompetitionModel';
import ParticipatesIn from '../models/ParticipatesInModel';
import Competitor from '../models/CompetitorModel';


import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import FieldParams from '../models/params/FieldParams';
import { convertAuDateIntoApiDate } from '../../utils/dateUtils';
import SectionRepository from './SectionRepository';
import CompetitorRepository from './CompetitorRepository';
import CompetitionRepository from './CompetitionRepository';
import ParticipatesInRepository from './ParticipatesInRepository';



class CsvUploaderRepository {

    getCsvModelListFromPapaParse(papaParseData) {

        const mappedData = papaParseData.map(
            (item) => Mapper.mapPapaParseCsvToCsvModel(item)
        );
        return mappedData
    }

    async uploadCSVDataToApi(papaParseData) {
        const sectionRepository = new SectionRepository();
        const competitorRepository = new CompetitorRepository();
        const competitionRepository = new CompetitionRepository();
        const participatesInRepository = new ParticipatesInRepository();



        const csvModelList = this.getCsvModelListFromPapaParse(papaParseData)
        console.log("Csvmodel List is: ",csvModelList.length)


        for (let i = 0; i < csvModelList.length; i++) {

            const csvModel = csvModelList[i]
            console.log("Csvmodel: ",csvModel)


            const sectionModel = await sectionRepository.createSectionWithSectionExistsCheck(new Section(null, csvModel.sectionCode, csvModel.sectionDescription))
            console.log("Section Model After Upload is: ", sectionModel)


            
            const competitorModel = await competitorRepository.createCompetitorWithCompetitorExistsCheck(new Competitor(null, csvModel.competitorsName, csvModel.competitorEmailAddress))
            console.log("Competitor Model After Upload is: ", competitorModel)

            const competitionModel = await competitionRepository.createCompetitionWithCompetitionExistsCheck(new Competition(null, null, null, csvModel.competitionDate, sectionModel.sectionId))
            console.log("Competition Model After Upload is: ", competitionModel)

            const participatesInModel = await participatesInRepository.createParticipatesWithCompetitionAndCompetitorExistsCheck(new ParticipatesIn(null, null, csvModel.competitorOrderNo, competitorModel.competitorId, competitionModel.competitionId))
            console.log("Participates In Model After Upload is: ", participatesInModel)


            // break

        }




    }








}

export default CsvUploaderRepository;
