import React, { useState } from "react";

import AuthRepository from '../../domain/repositories/AuthRepository';
import '../../assets/css/form.css';
import {LOGIN_MESSAGE} from '../../utils/constants/message_constants'

import { validateEmail, validatePassword,validateName} from '../../utils/validation';


// function LoginForm() {
const LoginForm = ({ onLogin }) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authRepository = new AuthRepository();


  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      // onLogin();
      // return


      const base_url = 'http://0.0.0.0:8055'
      var endpoint = '/auth/login'
      var login_url = base_url + endpoint

      setIsSubmitting(true);
      setError("");
      const errors = [];

      if (!validateName(username)) {
        errors.push(LOGIN_MESSAGE.USERNAME_VALID);
      }
      else if (!validatePassword(password)) {
        errors.push(LOGIN_MESSAGE.PASSWORD_VALID);
      }
      
      if (errors.length > 0) {
        setError(errors);
        setIsSubmitting(false);
      } else {

      await authRepository.login(username, password)
      setError(`Login Success`);
      setIsSubmitting(false);
      setUsername('')
      setPassword('')
      onLogin();
    }

      // const response = await fetch(login_url, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({'email':username, password }),
      // });

      // if (response.ok) {
      //   const data = await response.json();
      //   console.log(data)
      //   sessionStorage.setItem("access_token", data.data.access_token);
      // } else {
      //   setError("Invalid username or password");
      // }
    } catch (error) {
      console.error(error);
      setError(`${error}`);
      setIsSubmitting(false);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("access_token");
  };

  const accessToken = sessionStorage.getItem("access_token");

  if (accessToken) {
    return (
      <div>
        <p>You are logged in.</p>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  return (
    <div>

      <h2>Login</h2>

      <form className="form-container" onSubmit={handleLogin}>
        <div className="form-group">
          <label>
            Username
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </label>
        </div>
        <div className="form-group">

          <label>
            Password
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </label>

        </div>
        {/* <div className="form-group">
          <label>
            Test
            <input type="text" />
          </label>
        </div> */}


        <div className="form-group">

          <button type="submit">Login</button>
        </div>

        {isSubmitting && <div className="spinner"></div>}

      {error && <p className="error-message ">{error}</p>}      
      </form>
    </div>

  );
}

export default LoginForm;