
import apiClient from '../../api/apiClient';
import { API_ENDPOINTS_ITEMS, PAGE_LIMIT, API_FIELDS_STATUS, API_FIELDS_COMPETITOR, API_FIELDS_COMPETITION, API_ENDPOINTS_OBJECT, COMPETITION_STATUS } from '../../utils/constants/api_constants';
import Mapper from '../models/mapper/Mappers';
import Params from '../models/params/FilterParams';
import { convertAuDateIntoApiDate } from '../../utils/dateUtils'



class CompetitionRepository {

    async getCompetitionByDateAndSection(competitionDate, sectionId) {
        const apiDate = convertAuDateIntoApiDate(competitionDate)

        const filterParamsforCompetititon = JSON.stringify(Params.fetchCompetitionByDateAndSection(apiDate, sectionId))

        const response = await apiClient.get(`${API_ENDPOINTS_ITEMS.COMPETITION}?filter=${filterParamsforCompetititon}`);
        const competitionData = response.data;
        const competitionDataModel = []
        for (let i = 0; i < competitionData.length; i++) {
            competitionDataModel.push(Mapper.mapToCompetitionModel(competitionData[i]));
        }
        return competitionDataModel
    }

    async createCompetittion(competitionModel) {
        const apiDate = convertAuDateIntoApiDate(competitionModel.competitionDate)

        const competitionModelJsonObject = {
            [API_FIELDS_STATUS.STATUS]: API_FIELDS_STATUS.PUBLISHED,
            [API_FIELDS_COMPETITION.COMPETITION_STATUS]: COMPETITION_STATUS.IN_PROGRESS,
            [API_FIELDS_COMPETITION.COMPETITION_DATE]: apiDate,
            [API_ENDPOINTS_OBJECT.SECTION]: competitionModel.section,

        };

        const response = await apiClient.post(`${API_ENDPOINTS_ITEMS.COMPETITION}`, competitionModelJsonObject);
        const competitionData = response.data;
        const competitionDataModel = Mapper.mapToCompetitionModel(competitionData);
        return competitionDataModel
    }

    async createCompetitionWithCompetitionExistsCheck(competitionModel) {
        let competitionModelResponse = competitionModel

        try {
            const competitionData = await this.getCompetitionByDateAndSection(competitionModel.competitionDate, competitionModel.section);
            console.log('competitionData', competitionData)

            if (competitionData.length === 0) {
                competitionModelResponse = await this.createCompetittion(competitionModel);
                console.log('Competition does not exist in database. Created Competition now and got this response:', competitionModelResponse);
            }

            else {
                competitionModelResponse = competitionData[0]
                console.log(`Competition Date: ${competitionModelResponse.competitionDate} exist in database. Competition Id is ${competitionModelResponse.competitionId}`);
            }
        }

        catch (error) {
            throw new Error(`Error while creating Competition: ${error}`);

        }

        return competitionModelResponse
    }
}

export default CompetitionRepository;
