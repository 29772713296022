import Competitor from '../CompetitorModel';
import { API_FIELDS_COMPETITOR, API_FIELDS_STATUS, API_FIELDS_COMPETITION, API_ENDPOINTS_OBJECT, API_FIELDS_SECTION, API_FIELDS_PARTICIPATES_IN } from '../../../utils/constants/api_constants';


function fetchStatusParams(status) {
    return { [API_FIELDS_STATUS.STATUS]: { "_eq": status } }
}

function fetchCompetitorsByName(name) {

    // const nameParamsOr = { "_or": [{ [API_FIELDS_COMPETITOR.COMPETITOR_FIRST_NAME]: { "_starts_with": name } }, { [API_FIELDS_COMPETITOR.COMPETITOR_LAST_NAME]: { "_starts_with": name } }] }

    const nameParams = { [API_FIELDS_COMPETITOR.COMPETITOR_NAME]: { "_contains": name } }

    const statusPublished = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)

    const competitorsByNameParams = { "_and": [statusPublished, nameParams] }

    return competitorsByNameParams
}

function fetchCompetitorsByNameAndEmail(name,email) {

    const nameAndEmailParams = { "_and": [{ [API_FIELDS_COMPETITOR.COMPETITOR_NAME]: { "_eq": name } }, { [API_FIELDS_COMPETITOR.COMPETITOR_EMAIL]: { "_eq": email } }] }
    const statusPublished = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)
    const competitorsByNameEmailParams = { "_and": [statusPublished, nameAndEmailParams] }

    return competitorsByNameEmailParams
}

function fetchCompetitionByDateAndSection(competitionDate,sectionId) {
    const dateAndSectionParams = { "_and": [{ [API_FIELDS_COMPETITION.COMPETITION_DATE]: { "_eq": competitionDate } }, { [API_ENDPOINTS_OBJECT.SECTION]: { "_eq": sectionId } }] }
    const statusPublished = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)
    const competitionDateAndSectionParams = { "_and": [statusPublished, dateAndSectionParams] }
    return competitionDateAndSectionParams
}

function fetchCompetitionDateParams(startDate, endDate) {
    return { [API_FIELDS_COMPETITION.COMPETITION_DATE]: { "_between": [startDate, endDate] } }
}

function fetchCompetititorIdParams(competitorId) {
    return { [API_FIELDS_COMPETITOR.COMPETITOR_ID]: { "_eq": competitorId } }
}

function fetchSectionIdParams(sectionId) {
    return { [API_FIELDS_SECTION.SECTION_ID]: { "_eq": sectionId } }
}

function fetchParticipatesInAllParams(startDate, endDate, sectionId, competitorId) {

    const competitionDateParams = { [API_ENDPOINTS_OBJECT.COMPETITION]: fetchCompetitionDateParams(startDate, endDate) }

    const statusPublishedParticipatesIn = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)

    let fetchParticipationAllFilterParamsAndList = [statusPublishedParticipatesIn, competitionDateParams]

    if (sectionId != null) {
        const competitionSectionParams = { [API_ENDPOINTS_OBJECT.COMPETITION]: { [API_ENDPOINTS_OBJECT.SECTION]: fetchSectionIdParams(sectionId) } }
        fetchParticipationAllFilterParamsAndList.push(competitionSectionParams)
    }

    if (competitorId != null) {
        const competitorIdParams = { [API_ENDPOINTS_OBJECT.COMPETITOR]: fetchCompetititorIdParams(competitorId) }
        fetchParticipationAllFilterParamsAndList.push(competitorIdParams)
    }

    const fetchParticipationAllFilterParams = { "_and": fetchParticipationAllFilterParamsAndList }

    return fetchParticipationAllFilterParams

}


function fetchParticipatesInByCompetitionAndCompetitor(competition,competitor) {
    const competitionAndCompetitorParams = { "_and": [{ [API_ENDPOINTS_OBJECT.COMPETITION]: { "_eq": competition } }, { [API_ENDPOINTS_OBJECT.COMPETITOR]: { "_eq": competitor } }] }
    const statusPublished = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)
    const params = { "_and": [statusPublished, competitionAndCompetitorParams] }
    return params
}

function fetchParticipatesInByCompetitionAndRank(competition,rank) {
    const competitionAndRankParams = [{ [API_ENDPOINTS_OBJECT.COMPETITION]: { "_eq": competition } }, { [API_FIELDS_PARTICIPATES_IN.RANK]: { "_eq": rank } }] 
    const statusPublished = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)
    competitionAndRankParams.push(statusPublished)

    const params = { "_and": competitionAndRankParams }
    return params
}


function fetchSectionFilterParams(sectionField) {

    const statusPublishedParticipatesIn = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)
    let fetchSectionFilterParamsAndList = [statusPublishedParticipatesIn]
    if (sectionField || sectionField.trim() != '') {
        const sectionDescParams = { [API_FIELDS_SECTION.SECTION_DESC]: { "_contains": sectionField } }
        const sectionCodeParams = { [API_FIELDS_SECTION.SECTION_CODE]: { "_contains": sectionField } }
        const fetchSecDescOrSecCodeParams = { "_or": [sectionDescParams, sectionCodeParams] }
        fetchSectionFilterParamsAndList.push(fetchSecDescOrSecCodeParams)
    }
    const fetchSectionFilterParams = { "_and": fetchSectionFilterParamsAndList }
    return fetchSectionFilterParams
}

function fetchSectionFilterParamsToCheckSectionCodeExists(sectionCode) {

    const statusPublishedParticipatesIn = fetchStatusParams(API_FIELDS_STATUS.PUBLISHED)
    const sectionCodeFilter={ [API_FIELDS_SECTION.SECTION_CODE]: { "_eq": sectionCode } }
    const fetchSectionFilterParamsAndList = [statusPublishedParticipatesIn,sectionCodeFilter]
    const fetchSectionFilterParams = { "_and": fetchSectionFilterParamsAndList }
    return fetchSectionFilterParams
}

export default {
    fetchCompetitorsByName,
    fetchCompetitorsByNameAndEmail,
    fetchStatusParams,
    fetchParticipatesInAllParams,
    fetchParticipatesInByCompetitionAndCompetitor,
    fetchParticipatesInByCompetitionAndRank,
    fetchSectionFilterParams,
    fetchSectionFilterParamsToCheckSectionCodeExists,
    fetchCompetitionByDateAndSection
};