import { TOKEN } from '../../utils/constants/api_constants';


const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const ACCESS_TOKEN_EXPIRES_IN = 'access_token_expires_in';

class LocalStorageService {
  static serviceInstance = null;

  static getService() {
    if (!LocalStorageService.serviceInstance) {
      LocalStorageService.serviceInstance = new LocalStorageService();
    }
    return LocalStorageService.serviceInstance;
  }

  setAccessToken(token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  getAccessToken() {
    // return TOKEN;
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  setRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  setATExpiresIn(token) {
    localStorage.setItem(ACCESS_TOKEN_EXPIRES_IN, token);
  }

  getATExpiresIn() {
    return localStorage.getItem(ACCESS_TOKEN_EXPIRES_IN);
  }

  removeATExpiresIn() {
    localStorage.removeItem(ACCESS_TOKEN_EXPIRES_IN);
  }
}

export default LocalStorageService;