
class CsvUpload {
    constructor(competitorsName = null, sectionCode = null, sectionDescription = null, competitorOrderNo = null, competitionDate = null, competitorEmailAddress = null) {
        this.competitorsName = competitorsName;
        this.sectionCode = sectionCode;
        this.sectionDescription = sectionDescription;
        this.competitorOrderNo = competitorOrderNo;
        this.competitionDate = competitionDate
        this.competitorEmailAddress = competitorEmailAddress
    }

}

export default CsvUpload;
